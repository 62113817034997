import axios from 'axios'

// 创建axios实例
const _axios = axios.create({
  baseURL: '/api/' // api的base_url
})

const ERROR_MESSAGE_MAP = {
    0: '',
    100: '请求格式异常',
    101: '参数类型异常',
    102: '文件输入不识别',
    1000: '密码错误',
    1001: '支部或材料类目不存在',
    1002: '文件上传失败',
}

// response拦截器==>处理后端返回相应
_axios.interceptors.response.use(
  response => {
    if (response) {
      if (response.data) {
        const data = response.data
        if (data.code === 0) {
          return data.data
        } else {
          const message = ERROR_MESSAGE_MAP[data.code] || '未知错误'
          return Promise.reject(new Error(message))
        }
      } else {
        return Promise.reject(new Error('网络已断开'))
      }
    } else {
      return Promise.reject(new Error('运行时异常'))
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default _axios
