<template>
  <div id="app">
    <div v-if="!isLogin" class="login-page">
      <div class="login-box">
        <h1 class="login-page-title">材料收集系统</h1>
        <el-input class="login-page-password" placeholder="请在这里输入密码" v-model="password" type="password"></el-input>
        <el-button class="login-page-login-button" type="primary" @click="handleLogin()">登录</el-button>
      </div>

      <p class="login-page-footer">系统版本: 20201029.0321</p>
    </div>
    <div v-else class="upload-page">

      <div class="upload-box">
        <h1 class="upload-page-title">材料收集系统</h1>

        <el-form :model="form" class="upload-form">
          <el-form-item>
            <el-select v-model="form.folder" placeholder="请选择党支部" class="upload-form-select">
              <el-option
                v-for="f in folders"
                :key="f.id"
                :label="f.name"
                :value="f.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select v-model="form.channel" placeholder="请选择提交的材料" class="upload-form-select">
              <el-option
                v-for="c in channels"
                :key="c.id"
                :label="c.name"
                :value="c.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input v-model="form.name" placeholder="请输入你的姓名"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="handleSelectFile()">选择文件</el-button>
            <span> {{ currentFileName }}</span>
            <input ref="file" type="file" style="display: none" @change="handleFileChange()">
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleUpload()">上传</el-button>
          </el-form-item>
        </el-form>
      </div>

      <p class="upload-page-footer">
        <span class="noselect">Copyright &copy; 2018-2020 董江彬.</span>
        <span class="noselect">All rights reserved.</span>
        <a class="noselect" href="http://www.miit.gov.cn/">陕ICP备18011800号.</a>
      </p>
    </div>
  </div>
</template>

<script>
import request from './request'
import { Message } from 'element-ui'

export function requestLogin(password) {
  return request({
    url: '/login',
    method: 'post',
    data: {
      password
    }
  })
}

export function requestGetUploadFolders(password) {
  return request({
    url: '/get-folders',
    method: 'post',
    data: {
      password
    }
  })
}

export function requestUploadFile(password, folder, channel, name, file) {
  const data = new FormData()
  data.append('password', password)
  data.append('folder', folder)
  data.append('channel', channel)
  data.append('name', name)
  data.append('file', file, file.name)
  return request({
    url: '/upload',
    method: 'post',
    data
  })
}

export function toastError(error) {
  const message = error.message
  Message.error(message)
}

export default {
  name: 'App',
  data() {
    return {
      isLogin: false,
      password: '',
      form: {
        folder: undefined,
        channel: undefined,
        name: '',
        file: null
      },
      folders: []
    }
  },
  computed: {
    channels() {
      const folderID = this.form.folder
      if (typeof folderID === 'number') {
        const folder = this.folders.find(it => it.id === folderID)
        if (folder) {
          return folder.channels
        } else {
          return []
        }
      } else {
        return []
      }
    },
    currentFileName() {
      return this.form.file ? this.form.file.name : '还没有选择文件'
    }
  },
  methods: {
    async handleLogin() {
      try {
        await requestLogin(this.password)
        this.$message.success('登录成功')
        this.isLogin = true

        const data = await requestGetUploadFolders(this.password)
        this.folders = data
        console.log(data)
      } catch (err) {
        toastError(err)
      }
    },
    handleSelectFile() {
      const fileInput = this.$refs.file
      if (!fileInput) {
        this.$message.warning('暂时无法上传')
        return
      }

      fileInput.click()
    },
    handleFileChange() {
      const fileInput = this.$refs.file
      if (!fileInput) {
        console.warn('handleFileChange, fileInput not exist')
        return
      }

      if (fileInput.files[0]) {
        this.form.file = fileInput.files[0]
      }
    },
    handleUpload() {
      const { folder, channel, name, file } = this.form
      if (typeof folder !== 'number') {
        this.$message.error('必须选择所在党支部')
        return
      }

      if (typeof channel !== 'number') {
        this.$message.error('必须选择要上传的材料名')
        return
      }

      if (typeof name !== 'string' || name === '') {
        this.$message.error('姓名不能为空')
        return
      }

      if (file === null || file === undefined) {
        this.$message.error('还没有选择文件')
        return
      }

      this.doUpload({ folder, channel, name, file })
    },
    async doUpload({ folder, channel, name, file }) {
      try {
        await requestUploadFile(this.password, folder, channel, name, file)
        this.$message.success('上传成功')
      } catch (err) {
        toastError(err)
      }
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
body {
  color: #fff;
  background-color: #072e75;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.login-page,
.upload-page {
  box-sizing: border-box;
  height: 100vh;
  position: relative;
}
.login-box,
.upload-box {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 12px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}
.login-page-password,
.login-page-login-button {
  margin-top: 12px;
}
.login-page-footer {
  position: absolute;
  width: 100%;
  bottom: 12px;
  color: #aaa;
  text-align: center;
  font-size: 12px;
}

.upload-page-footer {
  position: absolute;
  bottom: 12px;
  width: 100%;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
}
.upload-page-footer a {
  text-decoration: none;
  color: white;
}
.upload-form {
  margin: 30px auto 0;
  max-width: 600px;
  width: 80%;
}
.upload-form-select {
  width: 100%;
}
</style>
